import React from 'react'
import { SocialIcon } from 'react-social-icons';
import './Footer.css'

export default ({ globalSettings, socialSettings, navLinks }) => (
    <footer className='Footer'>
        <div className='container taCenter'>
            <a className='Footer--Logo' href={'https://www.nei-isep.org'} target='_blank' rel="noopener noreferrer"
                 style={{
                     backgroundImage: 'url(/images/logo-2.png)'
                 }}>
            </a>

            <div className='Footer--Text-Container'>
                <h4>
                    Quem somos?
                </h4>
                <p className='Footer--Text'>
                    O NEI-ISEP (Núcleo de Estudantes de Informática do Instituto Superior de Engenharia do Porto) é um Núcleo criado por Estudantes de Engenharia Informática que pretende enriquecer a experiência dos diversos estudantes do ISEP durante a sua permanência no instituto a vários níveis relacionados com a nossa área de intervenção - Informática, as TIC e Novas Tecnologias.
                </p>
                <br/>
                <p className='Footer--Text'>
                    O NEI-ISEP pretende cultivar um espírito de comunidade e entreajuda, estabelecer ligações com o mercado empresarial e proporcionar experiências que permitam enriquecer diversas competências associadas à nossa área de intervenção.
                </p>
                <br/>
                <p className='Footer--Text'>
                    É objectivo do NEI-ISEP ser um órgão que pretende apoiar e representar os estudantes de Engenharia Informática do ISEP.
                </p>
            </div>


            <div className='Footer--Text-Container'>
                <SocialIcon url="https://twitter.com/nei_isep" className='Footer--SocialMediaIcon' bgColor="#ffa343" />
                <SocialIcon url="https://www.facebook.com/nei.isep" className='Footer--SocialMediaIcon' bgColor="#ffa343" />
                <SocialIcon url="https://www.instagram.com/nei_isep" className='Footer--SocialMediaIcon' bgColor="#ffa343" />
                <SocialIcon url="https://www.linkedin.com/company/nei-isep" className='Footer--SocialMediaIcon' bgColor="#ffa343" />
                <SocialIcon url="https://www.youtube.com/channel/UCPFWp4B34GoqxMaPbS7Xsjg" className='Footer--SocialMediaIcon' bgColor="#ffa343" />
            </div>

            <span>Copyright © 2020 NEI-ISEP. All rights reserved.</span>
        </div>
    </footer>
)
