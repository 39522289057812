import React from 'react'

import './ScheduleSection.css'
import Content from "./Content";

class ScheduleSection extends React.Component {

  render () {
    const { content, entries } = this.props.source;

    return (
      <div className='ScheduleSection'>
        <div className='container'>

          <h1 className='ScheduleSection--Title'>
            Horário
          </h1>

          <Content source={content} />

          <div className='ScheduleSection--Grid'>
            <div className='ScheduleSection--Entry'>
              <div className='ScheduleSection--TimeCol'><b>Hora</b></div>
              <div><b>Atividade</b></div>
            </div>
            <div className='ScheduleSection--Separator'/>
            {entries.map((entry, index) =>
                <>
                  <div key={index} className='ScheduleSection--Entry'>
                    <div className='ScheduleSection--TimeCol'>{entry.time}</div>
                    <div>{entry.description}</div>
                  </div>
                  <div className='ScheduleSection--Separator'/>
                </>
              )}
          </div>

        </div>
      </div>
    )
  }
}

export default ScheduleSection
