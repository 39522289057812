import React from 'react'
import { Link } from 'react-router-dom';

import Logo from './Logo'
import NavLink from './NavLink'
import './Nav.css'

export default ({ handlePopupOpen }) => (
    <nav className='Nav'>
        <div className='Nav--Container container'>
            <Link to='/' exact>
                <Logo />
            </Link>
            <NavLink to='#description' smooth>
                Evento
            </NavLink>
            <NavLink to='#stands' smooth>
                Stands Virtuais
            </NavLink>
            <NavLink to='#schedule' smooth>
                Horário
            </NavLink>
            {/*
     <NavLink to='/about/' exact>
        About
      </NavLink>
      <NavLink to='/blog/' exact>
        Blog
      </NavLink>
      <NavLink to='/contact/' exact>
        Contact
      </NavLink>*/}
        </div>
    </nav>
)
