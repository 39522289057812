import React from 'react'
import { Link } from 'react-router-dom'

import { slugify } from '../util/url'
import BackgroundImage from './BackgroundImage'
import LazyImage from "./LazyImage";
import './StandCard.css'

const StandCard = ({ standItem, standImage, className = '', ...props }) => (
  <Link
    to={slugify(`/stand/${standItem.companyName}/`)}
    className={`StandCard ${className}`}
    {...props}
  >
    {
      standImage?
          <LazyImage src={standImage} className='StandCard--Stand' alt='logo'/>
          :
          standItem.backgroundImage && (
                <div className='StandCard--Image relative'>
                  <BackgroundImage
                      src={standItem.backgroundImage}
                      alt={standItem.companyName}
                  />
                  <div className='container relative'>
                    {
                      standItem.logo &&
                      <LazyImage src={standItem.logo} className='StandCard--Logo' alt='logo'/>
                    }
                  </div>
                </div>
            )
    }

    {/*<div className='StandCard--Content'>
      {standItem.name && <h3 className='StandCard--Title'>{standItem.companyName}</h3>}
      {standItem.description && (
        <div className='StandCard--Excerpt'>
          {standItem.description.length > 160
            ? standItem.description.slice(0, 157) + '...'
            : standItem.description}
        </div>
      )}
    </div>*/}
  </Link>
)

export default StandCard
