import React from 'react'
import PropTypes from 'prop-types'

import './HomePageHeader.css'
import BackgroundImage from './BackgroundImage'
import LazyImage from "./LazyImage";
import { HashLink } from 'react-router-hash-link';
import ReactTypingEffect from 'react-typing-effect';

const HomePageHeader = ({
  title,
  subtitle,
  backgroundImage,
  logoImage,
  large,
  className = ''
}) => {
  if (large) className += ' HomePageHeader-large'
  return (
    <div className={`HomePageHeader relative ${className}`}>
      {backgroundImage && (
        <BackgroundImage src={backgroundImage} className='HomePageHeader--BackgroundImage' />
      )}
      <div className='relative'>
        <LazyImage src={logoImage} className='HomePageHeader--Logo'/>

        <h4>8 de janeiro</h4>

        <h1 className='HomePageHeader--Title'>{title}</h1>

        {subtitle && (
          <ReactTypingEffect
              staticText={subtitle}
              className='HomePageHeader--Subtitle'
              text={["for students.", "for companies.", "for enthusiasts.", "for your career prospects."]}
              typingDelay={0}
              eraseSpeed={100}
              eraseDelay={1000}
          />
        )}

        <div className="HomePageHeader--helper">
          <a target="_blank" rel="noopener noreferrer" href="https://fallstack2019.nei-isep.org/" className="HomePageHeader--LastEdition">
            Edição 2019
          </a>
        </div>

        <HashLink className="HomePageHeader--arrow" smooth to='#description' scroll={(el) => {
          el.scrollIntoView({behavior: 'smooth'});
          window.scrollBy(0, -100
          );
        }}>
          <span></span>
          <span></span>
          <span></span>
        </HashLink>
      </div>
    </div>
  )
}
HomePageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default HomePageHeader
