import React from 'react'
//import _sortBy from 'lodash/sortBy'

import './StandSection.css'
import StandCard from "./StandCard";

class StandSection extends React.Component {
  static defaultProps = {
    stands: [],
    title: '',
    limit: 12,
    showLoadMore: true,
    loadMoreTitle: 'Load More',
    perPageLimit: 12
  }

  state = {
    limit: this.props.limit
  }

  increaseLimit = () => {
    this.setState(prevState => ({
      limit: prevState.limit + this.props.perPageLimit
    }))
  }

  render () {
    const { stands, title, admin } = this.props

    return (
      <div className='StandSection'>
        <div className=''>
          <h1 className='StandSection--Title'>
            Stands
          </h1>
          {title && <h2 className='StandSection--Title'>{title}</h2>}

         {/* {!!visiblePosts.length && (
            <div className='StandSection--Grid'>
              {visiblePosts.map((postItem, index) =>
                <StandCard key={postItem.title + index} standItem={postItem} />
              )}
            </div>
          )}
*/}

          {admin.gold && !!admin.gold.length && (
              <div className='StandSection--Grid StandSection--Grid-Gold'>
                {admin.gold.map((standItem, index) => {
                  const stand = stands.find(stand => stand.companyName === standItem.stand);
                  return <StandCard key={stand.companyName + index} standItem={stand} tier={'gold'} standImage={standItem.standImage}/>
                })}
              </div>
          )}

          {admin.silver && !!admin.silver.length && (
              <div className='StandSection--Grid'>
                {admin.silver.map((standItem, index) => {
                  const stand = stands.find(stand => stand.companyName === standItem.stand);
                  return <StandCard key={stand.companyName + index} standItem={stand} tier={'silver'} standImage={standItem.standImage}/>
                })}
              </div>
          )}

          {admin.bronze && !!admin.bronze.length && (
              <div className='StandSection--Grid'>
                {admin.bronze.map((standItem, index) => {
                  const stand = stands.find(stand => stand.companyName === standItem.stand);
                  return <StandCard key={stand.companyName + index} standItem={stand} tier={'bronze'} standImage={standItem.standImage}/>
                })}
              </div>
          )}

          {admin.other && !!admin.other.length && (
              <div className='StandSection--Grid StandSection--Grid-Other'>
                {admin.other.map((standItem, index) => {
                  const stand = stands.find(stand => stand.companyName === standItem.stand);
                  return <StandCard key={stand.companyName + index} standItem={stand} tier={'other'} standImage={standItem.standImage}/>
                })}
              </div>
          )}

          {
            !admin.gold &&
            !admin.silver &&
            !admin.bronze &&
            !admin.other && (
                <h5>Stands virtuais disponíveis brevemente...</h5>
            )
          }

          {/*{showLoadMore &&
            visiblePosts.length < stands.length && (
            <div className='taCenter'>
              <button className='button' onClick={this.increaseLimit}>
                {loadMoreTitle}
              </button>
            </div>
          )}*/}
        </div>
      </div>
    )
  }
}

export default StandSection
