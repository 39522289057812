import React from 'react'

import Content from '../components/Content'
import './Home.css'
import Nav from '../components/Nav'
import HomePageHeader from "../components/HomePageHeader";
import ScheduleSection from "../components/ScheduleSection";
import StandSection from "../components/StandSection";
import EventSection from "../components/EventSection";


export default ({ fields, stands, admin }) => {
  const { title, subtitle, backgroundImage, logoImage, descriptionSection, videoSection, standsSection, scheduleSection, sponsorsSection } = fields

  return (
    <main className='Home'>
      <Nav />

      <HomePageHeader
        large
        title={title}
        subtitle={subtitle}
        backgroundImage={backgroundImage}
        logoImage={logoImage}
      />

      <div className='section Home--Section'>
        <div className='container'>
            <div id='description'>
                <EventSection source={descriptionSection}/>
            </div>

            <Content source={videoSection} />

            <div id='stands'>
                <Content source={standsSection} />
            </div>

            {!!stands.length && <StandSection stands={stands} admin={admin}/>}

            <div id='schedule'>
                <ScheduleSection source={scheduleSection}/>
            </div>

            <Content source={sponsorsSection} />
        </div>
      </div>
    </main>
  )
}
