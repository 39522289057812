import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { ChevronLeft } from 'react-feather'

import Content from '../components/Content'
import BackgroundImage from '../components/BackgroundImage'
import LazyImage from "../components/LazyImage";
import './Stand.css'
import ModalButton from "../components/ModalButton";
import { GrMail } from 'react-icons/gr';
import { GrLink } from 'react-icons/gr';
import { AiFillMessage } from 'react-icons/ai';
import { MdWork } from 'react-icons/md';
import ContactsModal from "../components/ContactsModal";
import LinksModal from "../components/LinksModal";
import JobOffersModal from "../components/JobOffersModal";

export default function Stand({ fields, location }) {
    const { companyName, backgroundImage, logo, description, content, contacts, links, jobOffers, zoomLink} = fields;
    const [contactsModalIsOpen, setContactsModalIsOpen] = useState(false);
    const [linksModalIsOpen, setLinksModalIsOpen] = useState(false);
    const [jobOffersModalIsOpen, setJobOffersModalIsOpen] = useState(false);

    return (
        <article className="Stand section light">
            {backgroundImage && (
                <BackgroundImage
                    className="Stand--BackgroundImage"
                    src={backgroundImage}
                    alt={companyName}
                />
            )}
            <div className='Stand--Header container relative'>
                {
                    logo &&
                    <LazyImage src={logo} className='Stand--Logo' alt='logo'/>
                }
            </div>

            <div className="container">
                <div className="Stand--Content relative">
                    {
                        //check if has router
                        location &&
                        <Link className="Stand--BackButton" to="/">
                            <ChevronLeft /> Voltar
                        </Link>
                    }
                    {companyName && <h1 className="Stand--Title">{companyName}</h1>}

                    <div className="Stand--InnerContent">
                        <Content source={description} />
                        <Content source={content} />
                    </div>

                    <div className="Stand--ModalButtons">
                        {
                            contacts && contacts.length > 0 &&
                            <>
                                <ModalButton label={'Contactos'} icon={<GrMail/>} onClick={() => setContactsModalIsOpen(true)}/>
                                <ContactsModal
                                    isOpen={contactsModalIsOpen}
                                    onRequestClose={() => setContactsModalIsOpen(false)}
                                    content={contacts}
                                />
                            </>
                        }
                        {
                            jobOffers && jobOffers.length > 0 &&
                            <>
                                <ModalButton label={'Ofertas'} icon={<MdWork/>} onClick={() => setJobOffersModalIsOpen(true)}/>
                                <JobOffersModal
                                    isOpen={jobOffersModalIsOpen}
                                    onRequestClose={() => setJobOffersModalIsOpen(false)}
                                    content={jobOffers}
                                />
                            </>

                        }
                        {
                            links && links.length > 0 &&
                            <>
                                <ModalButton label={'Links'} icon={<GrLink/>} onClick={() => setLinksModalIsOpen(true)}/>
                                <LinksModal
                                    isOpen={linksModalIsOpen}
                                    onRequestClose={() => setLinksModalIsOpen(false)}
                                    content={links}
                                />
                            </>
                        }
                        {
                            zoomLink &&
                            <ModalButton label={'Conversar'} icon={<AiFillMessage/>} onClick={() => {
                                let win = window.open(zoomLink, '_blank');
                                win.focus();
                            }}/>
                        }
                    </div>

                </div>
            </div>
        </article>
    )
}
