import React, {useState} from 'react';
import ReactPlayer from "react-player/youtube";
import './VideoPlayer.css';

function VideoPlayer({videoUrl = 'https://www.youtube.com/watch?v=4tLk6UIjF4M'}) {
    const [open, setOpen] = useState(true);

    return (
        <div className={open? 'VideoPlayer-Open' : 'VideoPlayer-Closed'}>
            <div className='VideoPlayer--ToggleButton' onClick={() => setOpen(!open)}>
                {
                    open?
                        'Esconder'
                        :
                        'Mostrar vídeo das entrevistas'
                }
            </div>
            <ReactPlayer
                url={videoUrl}
                controls={true}
                muted={true}
                playing={true}
            />
        </div>
    );
}

export default VideoPlayer;
