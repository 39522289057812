import React from 'react'
//import { NavLink } from 'react-router-dom'

import {HashLink} from 'react-router-hash-link';

import './NavLink.css'

export default ({className, children, ...props}) => (
    <HashLink {...props} className={`NavLink ${className || ''}`} scroll={(el) => {
        el.scrollIntoView({
            //behavior: "smooth"
        });
        window.scrollBy(0, -100);
    }}>
        {children}
    </HashLink>
)
