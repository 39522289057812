import React from 'react'

import './EventSection.css'
import Content from "./Content";

class EventSection extends React.Component {

  render () {
    const content = this.props.source;

    return (
      <div className='EventSection'>
        <div className='container'>

          <h1 className='EventSection--Title'>
            O que é o FallStack?
          </h1>

          <Content source={content} className='EventSection--Content' />


          <div className='EventSection--Activities-Container'>
            <div className='EventSection--Activity text-right'>
              <h2>
                Sessão de Entrevistas
              </h2>
              <Content source={'O objetivo da **sessão de entrevistas** é as empresas presentes darem-se a conhecer aos estudantes. Através de perguntas feitas pelos moderadores presentes é possível aos participantes perceber quais as empresas que melhor vão de encontro às suas expectativas para o estágio curricular a realizar.'}/>
            </div>
            <div className='EventSection--Activity-Image-Container'>
              <img src='/images/interview.png' alt='interview' className='EventSection--Activity-Image'/>
            </div>
          </div>

          <div className='EventSection--Activities-Container'>
            <div className='EventSection--Activity-Image-Container'>
              <img src='/images/roundtables.png' alt='round tables' className='EventSection--Activity-Image'/>
            </div>
            <div className='EventSection--Activity text-left'>
              <h2>
                Round Tables
              </h2>
              <Content source={'O objetivo das **Round Tables** é o estudante ter contacto com todas as empresas presentes no evento. Os participantes serão divididos em grupos de oito pessoas e terão um tempo limitado para, em grupo, esclarecer eventuais dúvidas que tenham surgido da entrevista da manhã ou simplesmente descobrir mais sobre a empresa em questão. Esta dinâmica irá ocorrer através do Zoom, sendo que cada empresa terá uma sala de Zoom e o estudante terá de trocar de sala à medida que o tempo acaba.'}/>
            </div>
          </div>


          <div className='EventSection--Activities-Container'>
            <div className='EventSection--Activity text-right'>
              <h2>
                Open Networking
              </h2>
              <Content source={'Sendo que o objetivo das Round Tables é uma conversa rápida com todas as empresas presentes no evento, no final do mesmo poderás ter contacto com as empresas que mais despertaram o teu interesse sem restrições de tempo! O Open Networking estará só disponível para quem se inscrever na sessão de Round Tables.'}/>
            </div>
            <div className='EventSection--Activity-Image-Container'>
              <img src='/images/networking.png' alt='interview' className='EventSection--Activity-Image'/>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default EventSection
