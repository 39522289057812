import React from 'react';
import './ModalButton.css';

function ModalButton({label, icon, onClick}) {
    return (
        <div className='ModalButton-container' onClick={onClick}>
            <div className='ModalButton-icon'>
                {icon}
            </div>
            <div className='ModalButton-label'>
                {label}
            </div>
        </div>
    );
}

export default ModalButton;
