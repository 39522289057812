import React from 'react';
import './ContactsModal.css';
import Modal from "react-modal";
import { GrClose } from 'react-icons/gr';


Modal.setAppElement(document.getElementById('root'));

function ContactsModal({isOpen, onRequestClose, content}) {
  return (
      <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel='Contactos'
          style={{overlay: {backgroundColor: '#101C2A88', zIndex: 1010}}}
          className='ContactsModal-container'
          shouldCloseOnOverlayClick={true}
      >


        <div className='ContactsModal-content'>

          <button onClick={onRequestClose} className='ContactsModal-close-btn'>
            <GrClose/>
          </button>


          <div className='ContactsModal-title'>
            Contactos
          </div>

          {
            content.map((c, i) =>
                <div className='ContactsModal-row' key={i}>
                  <div className='ContactsModal-label'>
                    {c.label}:
                  </div>

                  <div className='ContactsModal-value'>
                    {c.contact}
                  </div>
                </div>
            )
          }
        </div>
      </Modal>
  );
}

export default ContactsModal;
