import React from 'react';
import './JobOffersModal.css';
import Modal from "react-modal";
import { GrClose } from 'react-icons/gr';
import Content from "./Content";


Modal.setAppElement(document.getElementById('root'));

function JobOffersModal({isOpen, onRequestClose, content}) {
  return (
      <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel='Ofertas'
          style={{overlay: {backgroundColor: '#101C2A88', zIndex: 1010}}}
          className='JobOffersModal-container'
          shouldCloseOnOverlayClick={true}
      >


        <div className='JobOffersModal-content'>

          <button onClick={onRequestClose} className='JobOffersModal-close-btn'>
            <GrClose/>
          </button>


          <div className='JobOffersModal-title'>
            Ofertas de Estágio/Emprego
          </div>

          {
            content.map((c, i) =>
                <div className='JobOffersModal-job' key={i}>
                  <Content className='JobOffersModal-jobTitle' source={c.title}/>
                  <Content className='JobOffersModal-jobDescription' source={c.description} />

                  <p> <b>Link:</b>
                    <a className='JobOffersModal-jobLink' href={c.link} target='_blank' rel="noopener noreferrer">
                      {c.link}
                    </a>
                  </p>

                  <div className='JobOffersModal-separator'/>
                </div>
            )
          }
        </div>
      </Modal>
  );
}

export default JobOffersModal;
