import React from 'react';
import './LinksModal.css';
import Modal from "react-modal";
import { GrClose } from 'react-icons/gr';


Modal.setAppElement(document.getElementById('root'));

function LinksModal({isOpen, onRequestClose, content}) {
  return (
      <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel='Links'
          style={{overlay: {backgroundColor: '#101C2A88', zIndex: 1010}}}
          className='LinksModal-container'
          shouldCloseOnOverlayClick={true}
      >


        <div className='LinksModal-content'>

          <button onClick={onRequestClose} className='LinksModal-close-btn'>
            <GrClose/>
          </button>


          <div className='LinksModal-title'>
            Links Úteis
          </div>

          {
            content.map((c, i) =>
                <div className='' key={i}>
                    <div className='LinksModal-label'>{c.label}:</div>

                    <a className='LinksModal-value' href={c.link} target='_blank' rel="noopener noreferrer">
                      {c.link}
                    </a>
                </div>
            )
          }
        </div>
      </Modal>
  );
}

export default LinksModal;
